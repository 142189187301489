.modal-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
}

.resume-modal{
    position: absolute;
    top: 7%;
    background-color: white;
    width: calc(15% + 40vw);
    border-radius: 20px;
    height: auto;
}

.resume-handlebar {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1.5px solid #cacaca;
    padding: 0.7%;
    border-radius: 10px 10px 0 0;
    background: #fffbf5;
    opacity: 0.8;
}

.resume-close {
    display: flex;
    align-content: center;
    bottom: auto;
}

img {
    width: 100%;
    height: auto;
}

.resume-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 75vh;
    overflow-y: scroll;
}

@media (max-width: 1000px) {
    .resume-modal {
        width: 90vw;
        height: auto;
        padding-bottom: 3%;
    }
}