.dock-container {
    height: 70px;
    opacity: 1;
    background: rgba(255, 255, 255, 0.4);
    bottom: 10px;
    box-shadow: 2px 3px 3px 1px gray;
    position: absolute;
    width: 80%;
    display: inline-flex;
    border-radius: 20px 20px 20px 20px;
    margin-left: 10vw;
}

@media (max-width: 600px) {
    .dock-container {
        width: 94%;
        margin-left: 3vw;
    }
}

