.modal-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
}

.settings-modal{
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: white;
    width: 50vw;
    height: 50vh;
    border-radius: 20px;
}

.settings-handlebar {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1.5px solid #cacaca;
    padding: 0.7%;
    border-radius: 10px 10px 0 0;
    background: #fffbf5;
    opacity: 0.8;
}

.settings-close {
    display: flex;
    align-content: center;
    bottom: auto;
}

.settings-selector {
    display: flex;
    height: 10%;
    margin-top: 1%;
    justify-content: center;
}

.settings-contents {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 65vh;
    overflow-y: scroll;
    padding: 2%;
    padding-top: 0;
}

.settings-header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.settings-title {
    justify-content: center;
    user-select: none;
}

.icon-wrapper {
    display: flex;
    justify-content: center;
    
}

.settings-icon {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 1%;
    min-width: 4em;
}

.icon-icon {
    display: flex;
    justify-content: center;
    top: 0;
}

.icon-title {
    /*transform: rotate(80deg);*/
    text-align: center;
    font-size: 70%;
    margin-top: 20%;

}

.settings-body {
    display: flex;
    padding: 2%;
}

@media (max-width: 1000px) {
    .settings-modal {
        width: 90%;
        height: 50%;
    }

    .settings-close {
        margin-left: 4%;
    }
    
}