.window-container {
    display: inline-flex;
}

.img-wrap {
    display: flex;
    padding-top: 6px;
    max-width: 100%;
    max-height: 50vh;
    overflow: scroll;
    justify-content: center;
}

.info-img {
    max-width: 100%;
    object-fit:cover;
    overflow: scroll;
}

.content-wrap {
    
    width: 50%;
    display: block;
    justify-content: center;
    overflow-y: scroll;
    white-space: hidden;
    margin-left: 10%;
}

.content-title {
    display: flex;
    justify-content: center;
}

.content-description {
    display: flex;
    margin-right: 10%;
    flex-direction: column;
}

.content-tools {
    display: flex;
    font-weight: 700;
}

.content-url {
    display: flex;
    margin-right: 10%;
}

@media (max-width: 1000px) {
    .content-wrap {
        display: flex;
        flex-direction: column;
        width: 90%;
        position:relative;
    }

    .window-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
    }

    

   .img-wrap {
       max-height: 40%;
       object-fit: cover;
   }
    
}