.modal-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
}

.folder-modal{
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: white;
    width: calc(15% + 60vw);
    border-radius: 20px;
}

.folder-handlebar {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1.5px solid #cacaca;
    padding: 0.7%;
    border-radius: 10px 10px 0 0;
    background: #fffbf5;
    opacity: 0.8;
}

.folder-close {
    display: flex;
    margin-left: 2%;
}

.folder-nav-buttons {
    display: flex;
    margin-left: 4%;
}

.folder-contents {
    display: flex;
    justify-content: center;
    height: 65vh;
    overflow-y: scroll;
}

@media (max-width: 1000px) {
    .folder-modal {
        width: 90%;
        height: 85%;
    }

    .folder-close {
        margin-left: 5%;
    }

    .folder-nav-buttons {
        margin-left: 6%;
    }
    
}
