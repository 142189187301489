.modal-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
}

.message-modal{
    position: absolute;
    top: 7%;
    background-color: white;
    width: calc(15% + 40vw);
    border-radius: 20px;
}

.message-handlebar {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1.5px solid #cacaca;
    padding: 0.7%;
    border-radius: 10px 10px 0 0;
    background: #fffbf5;
    opacity: 0.8;
}

.message-close {
    display: flex;
    align-content: center;
    bottom: auto;
}

.message-header {
    display: flex;
    justify-content: center;
    margin-bottom: 0%;
}

.message-content {
    display: flex;
    flex-direction: column;
    padding: 2% 2% 7% 2%;
}

.input-wrap {
    display: flex;
    flex-direction: column;
    flex-grow: 3;
    height: 70%;
    margin-top: 1%;
}

.button-wrap {  
    display: flex;
    flex-grow: 1;
    position: absolute;
    padding: 2%;
    right: 0;
    bottom: 0;
}

.single-input {
    display: flex;
    padding-bottom: 1%;
}

.single-input > input {
    height: 95%;
    width: 70%;
    
}

.multi-input {
    display: flex;
    height: 40vh;
    flex-grow: 3;
}

textarea {
    width: 100%;
    border-radius: 4px;
    background-color: #f8f8f8;
    resize: none;
    padding-left: 3%;
    padding-right: 3%;
}

.button-wrap > input {
    display: flex;
    
}

@media (max-width: 1000px) {
    .single-input {
        padding-bottom: 7%;
    }
    .single-input > input {
        width: 100%;
    }
    .multi-input > textarea {
        height: 90%;
    }

    .button-wrap {
        right: 4%;
    }

    .message-modal {
        width: 90vw;
    }
    
}