.homeicon-item {
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    user-select: none;
}

.homeicon-text {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    text-align: center;
}