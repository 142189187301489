.navbar {
    background: rgba(255, 255, 255, 0.6);
    opacity: 0.6;
    height: 38px;
    display: flex;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    box-shadow: 0px 1px gray;
  }

.navbar-container {
    display: flex;
}

.navbar-left {
  display: flex;
  margin-left: 5%;
  align-items: center;
  justify-content: center;
}

.navbar-right {
  display: flex;
  position: absolute;
  right: 2%;
  align-items: center;
}

.navbar-name {
  color: rgb(0, 0, 0);
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  white-space: nowrap;
  padding-bottom: 21%;
  user-select: none;
}

.navbar-dropdown {
  cursor: pointer;
}

.dropdown-container-open {
  display: flex;
  position: absolute;
  justify-content: center;
  left: 0.4%;
  top: 40px;
  min-width: 15%;
  padding: 0px 5px 0px 5px;
  background-color: white;
  opacity: 0.8;
  border-radius: 6px 6px 6px 6px;
  box-shadow: 1.5px 1.5px gray;
}

.dropdown-container-close {
  display: none;
}

.dropdown-list {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  width: 100%;
}

.dropdown-item {
  display: flex;
  list-style: none;
  padding: 5% 10% 5% 10%;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
  border-bottom: 1px solid grey;
}

.dropdown-item[label]::after {
  content: attr(label);
  margin-left: 1%;
  transform: scale(0);
}

.dropdown-item[label]:hover::after {
  transform: scale(1);
}

.dropdown-item:hover {
  background-color: lightgrey;
  color: black;
}

.navbar-timestamp {
  
  font-size: 1rem;
  
  align-items: center;
  user-select: none;
  
}

.social-icons {
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-right: 1em;
}

.social-icon {
  display: flex;
  margin-left: 20px;
  cursor: pointer;
  color: black;
}

.social-icon:hover {
  transform: scale(1.2);
}

@media (max-width: 1000px) {

  .navbar-timestamp {
    font-size: 0.7rem;
    word-wrap: break-word;
  }

  .social-icon {
    display: flex;
    margin-left: 5px;
  }

}

@media screen and (min-width: 600px)  {
  .mobile-break { display: none; }
}

@media (max-width: 600px) {
  .dropdown-container {
    min-width: 50%;
  }
}