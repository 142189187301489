.dock-item {
    margin: 10px auto;
    cursor: pointer;
    user-select: none;
}

.dock-item > p {
    display: flex;
    justify-content: center;
    margin-top: auto;
}

.dock-icon {
    display: flex;
    justify-content: center;
   
}

.dock-item:hover {
    transform: scale(1.3) translate(0, -1vh);
}

.dock-icon-bounce {
    display: flex;
    justify-content: center;
    animation: bounce;
    animation-duration: 1s;
    animation-iteration-count: 1;
    
}

@keyframes bounce {
    0%   {transform: translate(0, 0vh);}
    45%  {transform: translate(0, -3.5vh);}
    
    55%  {transform: translate(0, -3.5vh);}
    100% {transform: translate(0, 0vh);}
  }

