.modal-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.modal{
    position: absolute;
    background-color: white;
    width: calc(15% + 60vw);
    height: auto;
    padding: 2rem;
    border-radius: 20px;
}

.modal-title {
    position: relative;
    border-bottom: 1px solid #dddddd;
    text-align: center;
}

.modal-subtitle {
    position: relative;
    text-align: left;
}

.close {
    display: inline-block;
}



